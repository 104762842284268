import {
    ANNOTATION_EDIT_URL,
    ANNOTATION_LOCATION,
    stringifyEntityRef,
  } from '@backstage/catalog-model';
  import Card from '@material-ui/core/Card';
  import CardContent from '@material-ui/core/CardContent';
  import CardHeader from '@material-ui/core/CardHeader';
  import Divider from '@material-ui/core/Divider';
  import IconButton from '@material-ui/core/IconButton';
  import { makeStyles } from '@material-ui/core/styles';
  import Grid from '@material-ui/core/Grid';
  import Typography from '@material-ui/core/Typography';
  import {
    InfoCardVariants,
    Link,
  } from '@backstage/core-components';
  import React, { useCallback } from 'react';
  import {
    alertApiRef,
    errorApiRef,
    useApi,
  } from '@backstage/core-plugin-api';
  import {
    catalogApiRef,
    useEntity,
  } from '@backstage/plugin-catalog-react';
  
  import CachedIcon from '@material-ui/icons/Cached';
  import EditIcon from '@material-ui/icons/Edit';
  import { useEntityPermission } from '@backstage/plugin-catalog-react/alpha';
  import { catalogEntityRefreshPermission } from '@backstage/plugin-catalog-common/alpha';
    
  const useStyles = makeStyles({
    gridItemCard: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 10px)',
      marginBottom: '10px',
    },
    fullHeightCard: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    gridItemCardContent: {
      flex: 1,
    },
    fullHeightCardContent: {
      flex: 1,
    },
    fieldLabel: {
      fontWeight: 'bold',
      marginBottom: '8px',
    },
    fieldValue: {
      marginBottom: '16px',
    },
  });
  
  export interface AwsAccountAboutCardProps {
    variant?: InfoCardVariants;
  }
  
  export function AwsAccountAboutCard(props: AwsAccountAboutCardProps) {
    const { variant } = props;
    const classes = useStyles();
    const { entity } = useEntity();
    const catalogApi = useApi(catalogApiRef);
    const alertApi = useApi(alertApiRef);
    const errorApi = useApi(errorApiRef);
    
    const { allowed: canRefresh } = useEntityPermission(
      catalogEntityRefreshPermission,
    );
  
    const entityMetadataEditUrl = entity.metadata.annotations?.[ANNOTATION_EDIT_URL];
  
    let cardClass = '';
    if (variant === 'gridItem') {
      cardClass = classes.gridItemCard;
    } else if (variant === 'fullHeight') {
      cardClass = classes.fullHeightCard;
    }
  
    let cardContentClass = '';
    if (variant === 'gridItem') {
      cardContentClass = classes.gridItemCardContent;
    } else if (variant === 'fullHeight') {
      cardContentClass = classes.fullHeightCardContent;
    }
  
    const entityLocation = entity.metadata.annotations?.[ANNOTATION_LOCATION];
    const allowRefresh =
      entityLocation?.startsWith('url:') || entityLocation?.startsWith('file:');
      
    const refreshEntity = useCallback(async () => {
      try {
        await catalogApi.refreshEntity(stringifyEntityRef(entity));
        alertApi.post({
          message: 'Refresh scheduled',
          severity: 'info',
          display: 'transient',
        });
      } catch (e) {
        errorApi.post(e);
      }
    }, [catalogApi, entity, alertApi, errorApi]);
  
    return (
      <Card className={cardClass}>
        <CardHeader
          title="About"
          action={
            <>
              {allowRefresh && canRefresh && (
                <IconButton
                  aria-label="Refresh"
                  title="Refresh"
                  onClick={refreshEntity}
                >
                  <CachedIcon />
                </IconButton>
              )}
              <IconButton
                component={Link}
                aria-label="Edit"
                disabled={!entityMetadataEditUrl}
                title="Edit"
                to={entityMetadataEditUrl ?? '#'}
              >
                <EditIcon />
              </IconButton>
            </>
          }
        />
        <Divider />
        <CardContent className={cardContentClass}>
            <Grid container spacing={2}>
                
                <Grid item xs={12} sm={4}>
                    <Typography variant="subtitle2" className={classes.fieldLabel}>
                        Account ID
                    </Typography>
                    <Typography variant="body2" className={classes.fieldValue}>
                        {entity?.metadata?.account_id || 'N/A'}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                <Typography variant="subtitle2" className={classes.fieldLabel}>
                    Owner
                </Typography>
                <Typography variant="body2" className={classes.fieldValue}>
                    {entity?.spec?.owner ? (
                      <Link to={`/catalog/default/group/${entity.spec.owner}`}>
                        {entity.spec.owner}
                      </Link>
                    ) : (
                      'N/A'
                    )}
                </Typography>
                </Grid>
                
                {entity?.spec?.type && (
                <Grid item xs={12} sm={4}>
                    <Typography variant="subtitle2" className={classes.fieldLabel}>
                    Type
                    </Typography>
                    <Typography variant="body2" className={classes.fieldValue}>
                    {entity.spec.type}
                    </Typography>
                </Grid>
                )}
                
                {entity?.spec?.email && (
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.fieldLabel}>
                    Distribution List
                    </Typography>
                    <Typography variant="body2" className={classes.fieldValue}>
                    {entity.spec.email}
                    </Typography>
                </Grid>
                )}
                
                {entity?.spec?.groups && entity.spec.groups.length > 0 && (
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.fieldLabel}>
                    Owner Okta Groups
                    </Typography>
                    <Typography variant="body2" className={classes.fieldValue}>
                    {entity.spec.groups.join(', ')}
                    </Typography>
                </Grid>
                )}
                
                {entity?.spec?.custom_permissions && entity.spec.custom_permissions.length > 0 && (
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.fieldLabel}>
                    Custom Permissions
                    </Typography>
                    <Typography variant="body2" className={classes.fieldValue}>
                    {entity.spec.custom_permissions.join(', ')}
                    </Typography>
                </Grid>
                )}
            </Grid>
            </CardContent>
      </Card>
    );
  }